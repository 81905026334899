import React, { createContext, useContext } from "react";

import en from "./en";
import pt from "./pt";

const LanguageContext = createContext(en);

const Language = ({ language, children }) => (
  <LanguageContext.Provider value={language === "pt" ? pt : en}>
    {children}
  </LanguageContext.Provider>
);

export const useT = () => {
  const language = useContext(LanguageContext);

  return { t: (key) => language[key] || `:${key}:`, language };
};

export default Language;
