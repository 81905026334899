import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import css from "@emotion/css";
import { Link as GatsbyLink } from "gatsby";

import { useT } from "../translations/translator";

import RawLogo from "../svgs/LogoLotuz.svg";
import RawLogoPetals from "../svgs/LogoLotuz-petals.svg";
import Container from "../components/container";

const Link = styled(GatsbyLink)`
  text-decoration: none;
  font-family: "Hightlight";
  color: var(--text-color-hightlight);
  z-index: 102;
`;

const navHeight = 60;

const Filler = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;

  position: relative;
  width: 100%;

  height: calc(var(--vh, 1vh) * 100 - ${navHeight}px);

  padding: 30px;

  h1 {
    margin-bottom: 40px;
  }
`;

const LogoContainer = styled.div`
  height: 100%;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  min-width: 50px;
`;

const LogoPetals = styled(RawLogo)`
  width: 100%;
  margin: auto;
  z-index: 101;

  padding-top: 5px;
  padding-bottom: 10px;

  top: 25%;
  height: 50%;
  // opacity: ${({ percentage }) => `${percentage}`};

  ${({ notext }) =>
    !notext
      ? ""
      : `
  height: 100%;
  padding: 0;
  margin-top: 6px;

  #g12,
  #g24,
  #g28,
  #g32,
  #g36 {
    display: none; 
  }
  `}
`;

const Nav = styled.nav`
  position: sticky;
  top: 0;
  width: 100%;
  height: ${navHeight}px;
  padding: 5px 0;
  z-index: 100;
  background: white;

  box-shadow: 0 10px 5px -5px rgba(0, 0, 0, 0.1);
`;

const NavContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
  height: 100%;
  align-items: center;

  > *:not(.small) {
    flex: 1;
  }

  > * {
    text-align: center;
    margin: 0 10px;
  }
`;

const Header = ({ hasHero, setSubject }) => {
  const { t } = useT();
  let [percentage, setPercentage] = useState(!hasHero ? 1 : 0);

  const percentageCallBack = () => {
    console.log(      Math.min(1, window.scrollY / (window.innerHeight - navHeight)))
    setPercentage(
      Math.min(
        1,
        Math.max(0.8, 1 - window.scrollY / (window.innerHeight - navHeight))
      )
    );
  };

  useEffect(() => {
    if (hasHero) {
      percentageCallBack();

      window.addEventListener("resize", percentageCallBack);
      document.addEventListener("scroll", percentageCallBack);
    }

    return () => {
      if (hasHero) {
        window.removeEventListener("resize", percentageCallBack);
        document.removeEventListener("scroll", percentageCallBack);
      }
    };
  }, []);

  return (
    <>
      {hasHero && (
        <Filler>
          <LogoPetals />
          <h1>{t("slogan")}</h1>
          <Link
            className="button"
            to={t("contactLink")}
            onClick={() => setSubject(t("cta0"))}
            css={css`
              width: max-content;
            `}
          >
            {t("cta0")}
          </Link>
        </Filler>
      )}
      <Nav>
        <NavContainer>
          <Link to={t("homeLink")} style={{ width: "100%", height: "100%" }}>
            <LogoPetals percentage={percentage} notext />
          </Link>
          <Link
            to={t("otherLanguageLink")}
            className="small"
            style={{ visibility: "hidden" }}
          >
            {t("otherLanguage")}
          </Link>
          {/* <Link to={t("portfolioLink")}>{t("portfolioTitle")}</Link> */}
          <Link to={t("serviceLink")}>{t("serviceTitle")}</Link>
          <Link to={t("aboutLink")}>{t("aboutTitle")}</Link>
          {/*<Link to={t("blogLink")}>{t("blogTitle")}</Link> */}
          <Link to={t("contactLink")}>{t("contactTitle")}</Link>
          <Link to={t("otherLanguageLink")} className="small">
            {t("otherLanguage")}
          </Link>
        </NavContainer>
      </Nav>
    </>
  );
};

Header.propTypes = {
  hasHero: PropTypes.bool,
};

const FullPage = styled.div`
  width: 100%;
  height: 100%;
  height: calc(100vh - ${navHeight});

  display: flex;
  justify-content: center;
  align-items: center;
`;

const Hero = () => {
  return (
    <FullPage>
      <Logo />
    </FullPage>
  );
};

export default Header;
