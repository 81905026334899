import multline from "./multline";

const lang = {
  language: "en",
  slogan: "We develop solutions, you reach goals!",
  areYouHuman: "Are you even human? 😲",
  homeTitle: "Home",
  homeDescription: `We are a solution focused development agency. With our
  computer science and web knowledge, we work hard to deliver high value
  solutions with amazing speed, allowing our clients to iterate faster on their
  ideas.  Contact us: contact@lotuz.dev`,
  aboutId: "about",
  aboutTitle: "About",
  aboutText: multline`We are a solution focused development agency.
  \n\n
  \n\n
  With our computer science and web knowledge, we work hard to deliver high
  value solutions with amazing speed, allowing our clients to iterate faster on
  their ideas.
  \n\n
  \n\n
  Lotuz is based in Brazil, but borders don't limit our reach. 
  `,
  serviceId: "services",
  serviceTitle: "Services",
  serviceText: ``,
  portfolioId: "portfolio",
  portfolioTitle: "Portfolio",
  portfolioText: "This is our portfolio",

  services: [
    {
      id: "serviceCustomDevelopment",
      title: "Custom web solution",
      fullWidth: true,
      description: multline`
      Whether you need a simple page, a new browser extension to engage with
      your clients or a new web app, you can count on us.
      \n\n
      \n\n
      We are a team of developers that alwyas keeps up to date with the
      latest web technologies, so we can choose the right tools for the job.
      You don't want to hammer a screw!
      \n\n
      \n\n
      Let's build your custom solution?
    `,
      cta: "Let's do it!",
    },
    {
      id: "serviceAutomation",
      title: "Automation",
      description: multline`Do you need data from those nasty tables on xyz
      website? 
      \n\n
      Or do you want an easier way to copy information from pdfs?
    `,
      cta: "Send me the data!",
    },
    {
      id: "serviceWebOptimization",
      title: "Website optimization",
      description: multline`Do you have a bad performing website? 
Is it a wordpress website or maybe a very old jQuery + Boostrap that is not loading fast enough anymore?
     `,
      cta: "Make it faster!",
    },
  ],

  servicesOld: [
    {
      id: "servicePreMadeWebsite",
      title: "One click website",
      description: multline`We have ready to deploy website templates at our disposal that you
can just fill the gaps with your content and it is ready to go. 
All of our website templates are contantly updated to the latest technologies
so they are fast, beautiful and inexpensive.
    `,
      cta: "Buy a website NOW!",
    },
    {
      id: "serviceECommerce",
      title: "E-commerce",
      description: multline`Your clients want to find about your products online, don't let them waiting!
Let them buy everything from the confort of their houses!
    `,
      cta: "Your business online!",
    },
    {
      id: "serviceCustomDevelopment",
      title: "Custom web development",
      description: multline`Want to develop a browser extension, a web game using canvas/webgl,
fix a WebRTC chat or use some other browser api? 
Talk to one of our developers!
\n
Or maybe you want a unique looking website, tailored for your own audience!
\n
What would be a better way to increase your online reach than
having a breathtaking unique website, built with the best
technologies that exist!
    `,
      cta: "Hire a developer!",
    },
    {
      id: "servicechatbot",
      title: "Customizable chat bot",
      description: multline`An assistant for every message, an answer for every channel.
\n
A simple chat bot to help your custommers online!
    `,
      cta: "Let's talk chatbot!",
    },
    {
      id: "serviceWebScrapping",
      title: "Web scrapping",
      description: multline`Do you need that table from that site or that info from that profile?
\n
Let our scrappers get the data for you!
    `,
      cta: "Get all the data!",
    },
    {
      id: "serviceWebOptimization",
      title: "Website optimization",
      description: multline`Do you have a bad performing website? 
Is it a wordpress website or maybe a very old jQuery + Boostrap that is not loading fast enough anymore?
\n
Let us improve it!
     `,
      cta: "Gotta go fast!",
    },
  ],

  blogTitle: "Blog",
  blogDescription: "Blog under construction",
  blogText: "We are currently building our blog, come back soon.",

  notFoundtitle: "404: Not Found",
  notFounddescription: "Error 404, page not found!",
  notFoundtext: "Hmm, there is nothing here. Let's",
  notFoundgoBack: "go back.",

  homeLink: "/",
  contactLink: "/#contact",
  aboutLink: "/#about",
  serviceLink: "/#services",
  portfolioLink: "/#portfolio",
  blogLink: "/blog",
  otherLanguageLink: "/pt",
  otherLanguage: "PT",

  cta0: "Get your solution!",
  contactUs: "Contact us!",

  contactId: "contact",
  contactTitle: "Contact",
  contactFormName: "Name:",
  contactText: multline`
    Tell us about your needs and we will contact you as soon as possible
    with your quote, or, if needed, with questions about your project.
  `,
  contactFormEmail: "E-mail:",
  contactFormBody: "Text:",
  contactFormSubmit: "Send",

  emailSuccess: "Thank you, we will reach out soon!",
  emailFailure: "We are sorry, but the email failed, please try again later!",
};

export default lang;
