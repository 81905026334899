import React from "react";

export default function multline(strings) {
  return strings[0].split("\n\n").map((line) => (
    <span>
      {line}
      <br />
    </span>
  ));
}
