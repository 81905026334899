import multline from "./multline";

const lang = {
  language: "pt",
  slogan: "Nós desenvolvemos soluções, você alcança objetivos!",
  areYouHuman: "Você é realmente humano? 😲",
  homeTitle: "Principal",
  homeDescription: `Nós somos uma agência de desenvolvimento focada em soluções.
  Com nosso conhecimento em ciẽncias da computação e web, nós trabalhamos duro
  para engregar soluções de alto valor em tempos incríveis, permitindo que
  nossos clientes iterem mais rápido em suas ideias. Entre em contato:
  contact@lotuz.dev`,
  aboutId: "sobre",
  aboutTitle: "Sobre",
  aboutText: multline`
  Nós somos uma agência de desenvolvimento focada em soluções. 
  \n\n
  \n\n
  Com nosso conhecimento em ciências da computação e web, nós trabalhamos duro
  para entregar soluções de alto valor em tempos incríveis, permitindo que
  nossos clientes iterem mais rápido em suas ideias.
  \n\n
  \n\n
  Lotuz tem sede no Brasil, mas fronteiras não limitam nosso alcance.
  `,
  serviceId: "servicos",
  serviceTitle: "Serviços",
  serviceText: ``,
  portfolioId: "portfolio",
  portfolioTitle: "Portfolio",
  portfolioText: "Esse é o nosso portfolio",

  services: [
    {
      id: "serviceCustomDevelopment",
      title: "Soluções web personalizadas",
      fullWidth: true,
      description: multline`
      Independente se você precisa de um simples site, uma nova extensão de
      navegador para engajar com seus clientes ou um novo aplicativo web, você
      pode contar conosco.
      \n\n
      \n\n
      Nosso time de desenvolvedores está sempre atualizado com as últimas
      tecnologias web, para escolhermos a ferramenta certa para o trbalho. Você
      não quer martelar um parafuso!
      \n\n
      \n\n
      Vamos construir sua solução?
    `,
      cta: "Vamos!",
    },
    {
      id: "serviceAutomation",
      title: "Automação",
      description: multline`
      Você precisa dos dados daquelas tabelas no site xyz?
      \n\n
      Ou você precisa de uma maneira mais fácil de copiar informações de pdfs?
    `,
      cta: "Me envie os dados!",
    },
    {
      id: "serviceWebOptimization",
      title: "Otimização de sites",
      description: multline`Você tem um site com baixa performance?  É um site
      feito em wordpress ou talvez seja um velho site usando jQuery e Boostrap
      que já não carrega rápido o suficiente?  `,
      cta: "Faça ir mais rápido!",
    },
  ],

  servicesOld: [
    {
      id: "servicePreMadeWebsite",
      title: "Site em um clique",
      description: multline`Nós temos à nossa disposição diversos sites prontos para entrega que você só tem que preencher
os espaços com seu conteudo e está pronto para ir online.
Todos os nossos sites são constantemente atualizados com as últimas tecnologias, o que os faz rápidos bonitos e baratos.
    `,
      cta: "Compre um site agora!",
    },
    {
      id: "serviceECommerce",
      title: "E-commerce",
      description: multline`Seus clientes querem encontrar seus produtos online, não os deixe esperando!
Deixe que comprem tudo online do conforto de suas casas!
    `,
      cta: "Minha loja online!",
    },
    {
      id: "serviceCustomDevelopment",
      title: "Desenvolvimento web sob demanda",
      description: multline`Quer uma extensão de browser, um jobo usando canvas/webgl, arrumar aquele chat
feito com WebRTC ou desenvolver usando alguma api específica do browser?
Converse com um de nossos desenvolvedores!
\n
Ou melhor! Você quer um site único, confeccionado para a sua audiência!
\n
O que poderia ser melhor para subir seu alcance online que
ter uma página de tirar o fôlego, construida usando as melhores 
tecnologias existentes!
    `,
      cta: "Contrate um desenvolvedor!",
    },
    {
      id: "servicechatbot",
      title: "Customizable chat bot",
      description: multline`Um assistente para cada mensagem, uma resposta para cada canal.
\n
Um chat bot simple para ajudar seus clientes online!
    `,
      cta: "Vamos conversar chatbot!",
    },
    {
      id: "serviceWebScrapping",
      title: "Web scrapping",
      description: multline`Você precisa da tabela daquele site ou uma informação de um perfil?
\n
Deixe que nossos scrappers peguem os dados para você!
    `,
      cta: "Pegue todos os dados!",
    },
    {
      id: "serviceWebOptimization",
      title: "Otimização de página",
      description: multline`Você tem um site com baixa performance?
É um site feito em wordpress ou talvez seja um velho site usando jQuery e Boostrap que já não carrega rápido o suficiente?
\n
Deixe que nós aperfeiçoaremos o seu site! 
     `,
      cta: "Tenho que ser rápido!",
    },
  ],

  blogTitle: "Blog",
  blogDescription: "Blog em construção",
  blogText: "Estamos construindo nosso blog, volte em breve.",

  notFoundtitle: "404: Pagina não encontrada",
  notFounddescription: "Erro 404, página não encontrada!",
  notFoundtext: "Hmm, não há nada aqui. Vamos",
  notFoundgoBack: "voltar.",

  homeLink: "/pt",
  contactLink: "/pt#contato",
  aboutLink: "/pt#sobre",
  serviceLink: "/pt#servicos",
  portfolioLink: "/pt#portfolio",
  blogLink: "/pt/blog",
  otherLanguageLink: "/",
  otherLanguage: "EN",

  cta0: "Quero minha solução!",
  contactUs: "Entre em contato!",

  contactId: "contato",
  contactTitle: "Contato",
  contactFormName: "Nome:",
  contactText: multline`
    Nos conte sobre suas necessidades e nós iremos entrar em contato o mais
    breve possível com um orçamento, ou, caso necessário, com perguntas sobre
    seu projeto.
  `,
  contactFormEmail: "E-mail:",
  contactFormBody: "Texto:",
  contactFormSubmit: "Enviar",

  emailSuccess: "Muito obrigado, em breve entraremos em contato.",
  emailFailure:
    "Mil desculpas, mas o e-email falhou, tente novamente mais tarde!",
};

export default lang;
