import React from "react";
import css from "@emotion/css";

import Container from "../components/container";

const Footer = () => {
  return (
    <Container
      css={css`
        padding: 1rem 10px;
        display: flex;
        justify-content: space-between;
      `}
    >
      © 2020 - Lotuz
      <a
        href="mailto:contact@lotuz.dev"
        rel="noopener noreferrer"
        target="_blank"
      >
        contact@lotuz.dev
      </a>
    </Container>
  );
};

export default Footer;
